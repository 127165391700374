// 接口url
let constUrl = 'https://xbigtest.lookdoor.cn:81'

switch (process.env.VUE_APP_ENV) {
  case 'prd': // 线上
    constUrl = ''
    break
  case 'sit': // 测试
    constUrl = ''
    break
  default: // 默认开发
    constUrl = '/api'
    // constUrl = 'https://l31311r335.oicp.vip'
}

export default constUrl